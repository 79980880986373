import Head from "next/head";

const MetaTags = ({ metas }) => {
    // Funcion que genera de manera dinamica los atributos y propiedades del tag meta, de la nueva estructura
    const createAttributeMeta = ({ type, meta, value }) => {
        return {
          [type]: meta,
          content: value,
        }
      }

      const HrefLinkDefault = `${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/img-default-share.jpeg`
    
    return (
        <Head>
            { metas && metas.map((element) => {

                if (Object.keys(element).length === 4)
                return (
                    <>
                        {
                        element.html === 'meta' 
                        ? <meta {...createAttributeMeta(element)}/>
                        : <link rel={element.meta} href={element.value.length === 0 ? HrefLinkDefault : element.value}/>
                        }
                    </>
                )

                return (
                    <>
                        {Object.keys(element).includes('image_src') && <link rel={"image_src"} href={element.image_src.length === 0 ? HrefLinkDefault : element.image_src} key={"image_src"} />}
                    </>
                )
            
                
                            
                    })}
        </Head>
    )
    
}

export default MetaTags;