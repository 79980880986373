import Script from 'next/script';

const Chartbeat = (props) => {
    
    const uid = 65646;
    const domain = 'ellitoral.com';

    const {
        sections,
        authors
    } = props;
    
    let sectionsPage = '';
    let authorsPage = '';
    let sectionsList = [];
    let authorsList = [];

    if(sections != undefined){
        if(Array.isArray(sections)){
            if(sections.length > 1 ){
                sections.map((section) => {
                    sectionsList.push(section.name);
                })
                sectionsPage = sectionsList.join(',');
            }
            else{
                sectionsPage = sections[0].name;
            }
        }
        else{
            sectionsPage = sections.name;
        }
    }

    if(authors != undefined){
        if(Array.isArray(authors)){
            if(authors.length > 1 ){
                authors.map((author) => {
                    authorsList.push(author.name);
                })
                authorsPage = authorsList.join(',')
            }
            else{
                authorsPage = authors[0].name; 
            }
        }
        else{
            authorsPage = authors.name;
        }
    }

    return (
        <>
            <Script  id="Chartbeat" strategy="afterInteractive">
                {`
                    (function() {
                        /** CONFIGURATION START **/
                        var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
                        _sf_async_config.uid = ${uid}; //CHANGE THIS
                        _sf_async_config.domain = '${domain}'; //CHANGE THIS
                        _sf_async_config.useCanonical = true;
                        _sf_async_config.useCanonicalDomain = true;
                        _sf_async_config.sections = '${sectionsPage}'; //CHANGE THIS TO YOUR SECTION NAME(s)
                        _sf_async_config.authors = '${authorsPage}'; //CHANGE THIS TO YOUR AUTHOR NAME(s)
                        _sf_async_config.flickerControl = false;
                        /** CONFIGURATION END **/
                        function loadChartbeat() {
                            var e = document.createElement('script');
                            var n = document.getElementsByTagName('script')[0];
                            e.type = 'text/javascript';
                            e.async = true;
                            e.src = '//static.chartbeat.com/js/chartbeat.js';
                            n.parentNode.insertBefore(e, n);
                        }
                        loadChartbeat();
                     })();
                `}
            </Script>
            <Script src="//static.chartbeat.com/js/chartbeat_mab.js" strategy="afterInteractive" />
        </>
    )
}


export default Chartbeat;